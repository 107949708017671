<template>
  <div id="container">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet.heat'
import axios from 'axios'
export default {
  name: 'Heatmap',
  data () {
    return {
      center: [48.7176219, 9.1890975],
      map: null
    }
  },
  methods: {
    setupLeafletMap: function () {
      const mapDiv = L.map('mapContainer').setView(this.center, 14)
      this.map = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        maxZoom: 18
      }).addTo(mapDiv)
      axios.get('https://lebenswert-heatmap.mieyu.de/api/positions.php').then((response) => {
        console.log(response)
        if (response.data == null) {
          response.data = []
        }
        this.positions = response.data
        this.heatmap = L.heatLayer(this.positions).addTo(mapDiv)
      })
    }
  },
  mounted () {
    this.setupLeafletMap()
  }
}
</script>

<style lang="scss">
  #mapContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
  }
</style>
